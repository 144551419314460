import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image';
import React from 'react';
import Container from '../Layout/SharedStyles/Container';
import style from './hero.mod.scss';
import MultipleButtons from '../Button/MultipleButtons';

const Hero = ({ heading, text, image, mobileImage, links }) => {
  const classes = [style.hero];

  const images = withArtDirection(getImage(image), [
    {
      media: '(max-width: 767px)',
      image: getImage(mobileImage),
    },
  ]);

  !text && classes.push(style[`hero--top-padding`]);

  return (
    <div className={[...classes].join(' ')}>
      <div className={style.hero__wrapper}>
        <GatsbyImage
          className={style.hero__image}
          image={images}
          alt={image.alt || ''}
        />
        <div className={style.hero__tint}>
          <div className={style.hero__overlay}>
            <Container width={'small'}>
              <div className={style.hero__content}>
                <h1>{heading}</h1>
                <p>{text}</p>
                {links && (
                  <MultipleButtons
                    buttons={links}
                    light
                    twoRowMobile
                    alignCenter
                  ></MultipleButtons>
                )}
              </div>
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
