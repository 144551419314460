import React from 'react';
import { graphql } from 'gatsby';
import { PageWrapper } from '../components/Layout/PageWrapper';
import { PageIntro } from '../components/Layout/PageIntro/PageIntro';
import ModuleArea from '../components/ModularContent/ModuleArea';
import PageLead from '../components/PageLead/PageLead';
import Hero from '../components/Hero/Hero';

const OtherPagesTemplate = ({
  data: {
    datoCmsOtherPage: {
      title,
      modularContent,
      id,
      metaTags,
      pageLeadText,
      heroHeading,
      heroImage,
      locale 
    },
    homeCrumb,
  },
  pageContext,
}) => {
  const breadcrumbs = [
    { title: homeCrumb.title, id: homeCrumb.id },
    { title: title, id: id },
  ];

  return (
    <PageWrapper pageData={pageContext} metaTags={metaTags}>
      <PageIntro title={title} breadcrumbs={breadcrumbs} />
      {heroHeading && heroImage && (
        <Hero heading={heroHeading} image={heroImage} mobileImage={heroImage} />
      )}
      {pageLeadText && <PageLead leadText={pageLeadText} />}
      {modularContent.length > 0 && <ModuleArea blocks={modularContent} locale={locale}/>}
    </PageWrapper>
  );
};

export default OtherPagesTemplate;

export const query = graphql`
  query OtherPagesQuery($locale: String!, $id: String!) {
    homeCrumb: datoCmsHomepage(locale: { eq: $locale }) {
      locale
      title
      id: originalId
    }
    datoCmsOtherPage(locale: { eq: $locale }, originalId: { eq: $id }) {
      locale
      title
      pageLeadText
      heroHeading
      heroImage {
        alt
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { fm: "webp", h: "600", fit: "crop", w: "1440", q: 60 }
        )
      }
      metaTags {
        title
        description
        image {
          url
        }
      }
      id: originalId
      modularContent {
        ... on DatoCmsContentBlock {
          ...Content
        }
        ... on DatoCmsSliderStandardWidthBlock {
          ...SliderStandardWidth
        }
        ... on DatoCmsImageBlock {
          ...Image
        }
        ... on DatoCmsContentWithMediaBlock {
          ...ContentWithMedia
        }
        ... on DatoCmsFeatureBlock {
          ...Feature
        }
        ... on DatoCmsDocumentListBlock {
          ...DocumentList
        }
        ... on DatoCmsLeadBlock {
          ...LeadBlock
        }
        ... on DatoCmsButtonListBlock {
          ...ButtonList
        }
        ... on DatoCmsVideoBlock {
          ...Video
        }
        ... on DatoCmsBuyersGuideFormBlock {
          ...BuyersGuideForm
        }
        ... on DatoCmsPathwaysBlock {
          ...PathwaysBlock
        }
      }
    }
  }
`;
